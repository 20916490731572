import {defineStore} from "pinia";
import {ref} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import {useToastNotificationsStore} from "@/Stores/ToastNotificationsStore";

export const usePostStore = defineStore("post-store", () => {

	const post = ref(null);
	const user = ref(usePage().props.auth.user);
	const menuOpened = ref(false);
	const toastNotificationsStore = useToastNotificationsStore()

	const setCurrentPost = (current) => {
		post.value = current
	}
	const like_unlike = () => {
		let url = route('post.like', post.value.uuid)

		window.axios.post(url).then(response => {
			post.value.meta = response.data
		})
	}
	const dislike = () => {
		if (!user.value) {
			// signup
			// router.visit(route('register'))
		}

		let url = route('post.dislike', post.value.uuid)

		window.axios.post(url).then(response => {
			post.value.meta = response.data

			toastNotificationsStore.add({
				message: "Post disliked.",
				type: "success"
			})
		})
	}
	const follow_unfollow = () => {
		if (user.value) {
			let url = route('creator.follow', post.value.creator.slug)
			window.axios.post(url).then(response => {

				post.value.creator.meta = response.data

				for (const [key, post] of Object.entries(usePage().props.posts.data)) {
					if (post.creator.id === post.creator.id) {
						post.creator.meta.following = response.data.following
					}
				}
			})
		} else {
			// signup
			router.visit(route('register'))
		}
	}
	const share = () => {
		try {
			window.navigator.share({
				url: post.value.url,
				text: 'Check this awesome video',
				title: post.value.title
			})
		} catch (e) {
			navigator.clipboard.writeText(post.value.url);

			toastNotificationsStore.add({
				message: 'link copied to clipboard',
				type: 'success'
			})
		}
	}
	const toggleMenu = (show = null) => {
		if (null === show) {
			menuOpened.value = !menuOpened.value
		} else {
			menuOpened.value = show
		}
	}
	const report = () => {
		// mailto:report@fikfap.com?subject=Report%20post%20%23258923&body=I%20want%20to%20report%20the%20post%20https://fikfap.com/post/258923%20because:%0D%0A%0D%0A%0D%0A(sent%20from%20App%20Version%204775%20App%20Hash%20db9c09561d995f6529121f9b6d9b175230acf3a3%20Platform%20desktop:%20Chrome%20113.0.0.0%20running%20on%20macOS%20Catalina,%20Feed%20Variant%20Default)'
	}

	return {
		post,
		menuOpened,
		setCurrentPost,
		toggleMenu,
		like_unlike,
		dislike,
		follow_unfollow,
		share
	};
});
